import Link from "next/link";
import appData from "@data/app.json";
import { useEffect } from "react";
import ImageView from "@components/ImageView";
import { footerSticky } from "@common/utilits";

const Footer2 = () => {
  useEffect(() => {
    footerSticky();
  }, []);

  return (
    <>
        {/* Footer */}
        <footer className="onovo-footer footer--white">
			<div className="footer--default">
				<div className="container">

					<div className="row gap-bottom-40">
						<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">

							{/* Heading */}
							<div className="onovo-heading">
								<h2 className="onovo-title-2">
									<span>Let’s Build Something <br/>Awesome Together</span>
								</h2>
								<p><Link href="/contact" className="onovo-footer-lnk onovo-lnk lnk--revert">Lets Start Project</Link></p>
							</div>

						</div>
					</div>

					<div className="row gap-top-20">
						<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
							<div className="onovo-f-logo gap-bottom-50" style={{"maxWidth": "180px"}}>
								<Link href="/">
									<img src={appData.footer.logo.image} alt={appData.footer.logo.alt} />
								</Link>
							</div>
							{/* Description */}
							<div className="onovo-text">
							We specialize in creating stunning, user-friendly websites that not only impress visitors but also drive results. Our team of skilled developers combines creativity with technical expertise to deliver custom web solutions.
							</div>

						</div>
						<div className="col-xs-12 col-sm-12 col-md-6 col-lg-3">

							{/* Description */}
							<div className="onovo-text">
								<ul className="onovo-footer-menu">
									<li>
										<Link href="/">
											<span className="onovo-lnk">Home</span>
										</Link>
									</li>
									<li>
										<Link href="/about">
											<span className="onovo-lnk">About Us</span>
										</Link>
									</li>
									
									<li>
										<Link href="/publications">
											<span className="onovo-lnk">Publications</span>
										</Link>
									</li>
									<li>
										<Link href="/contact">
											<span className="onovo-lnk">Contact Us</span>
										</Link>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-xs-12 col-sm-12 col-md-6 col-lg-3">

							{/* Description */}
							<div className="onovo-text">
								<ul className="onovo-footer-menu">
									<li>
										<Link href="/web-development">
											<span className="onovo-lnk">Web Development</span>
										</Link>
									</li>
									<li>
										<Link href="/digital-marketing">
											<span className="onovo-lnk">Digital Marketing</span>
										</Link>
									</li>
									<li>
										<Link href="/mobile-app-development">
											<span className="onovo-lnk">Mobile App Development</span>
										</Link>
									</li>
								</ul>
							</div>

						</div>
					</div>

					<div className="row">
						<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 align-self-center">
							<div className="copyright">
								<div dangerouslySetInnerHTML={{__html: appData.footer.copy}} />
							</div>
						</div>
						<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 align-right">

							{/* Social*/}
							<div className="onovo-social-1 onovo-social-active">
								<ul>
									{appData.social.map((item, key) => (
									<li key={`fsocial-item-${key}`}>
										<a className="onovo-social-link onovo-hover-2" href={item.link} title={item.title} target="_blank">
											<i className={item.icon} />
										</a>
									</li>
									))}
								</ul>
							</div>

						</div>
					</div>

				</div>
			</div>
		</footer>

        <ImageView />
    </>
  );
};
export default Footer2;
