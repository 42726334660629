import FooterPage from "./Footer";

const Footer = ({ footer }) => {
  switch (footer) {
    case 1:
      return <FooterPage />;
    case 2:
      return <FooterPage />;
    
    default:
      return <FooterPage />;
  }
};
export default Footer;
